import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faBuilding } from '@fortawesome/free-solid-svg-icons/faBuilding';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons/faBriefcase';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons/faFileInvoice';

import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { faListAlt } from '@fortawesome/free-solid-svg-icons/faListAlt';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faClipboard } from '@fortawesome/free-solid-svg-icons/faClipboard';

import { faArchive } from '@fortawesome/free-solid-svg-icons/faArchive';
import { faBalanceScale } from '@fortawesome/free-solid-svg-icons/faBalanceScale';
import { faBookOpen } from '@fortawesome/free-solid-svg-icons/faBookOpen';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt';
import { faCalculator } from '@fortawesome/free-solid-svg-icons/faCalculator';

import { faKeyboard } from '@fortawesome/free-solid-svg-icons/faKeyboard';

export const iconKey = [
  { name: 'person', icon: faUser },
  { name: 'users', icon: faUsers },
  { name: 'office', icon: faBuilding },
  { name: 'briefcase', icon: faBriefcase },
  { name: 'document', icon: faFileInvoice },

  { name: 'lock', icon: faLock },
  { name: 'list', icon: faListAlt },
  { name: 'info', icon: faInfoCircle },
  { name: 'envelope', icon: faEnvelope },
  { name: 'clipboard', icon: faClipboard },

  { name: 'archive', icon: faArchive },
  { name: 'law', icon: faBalanceScale },
  { name: 'book', icon: faBookOpen },
  { name: 'calendar', icon: faCalendarAlt },
  { name: 'calculator', icon: faCalculator },

  { name: 'keyboard', icon: faKeyboard },
];

const Icon = ({ name }) => {
  const icon = iconKey.find((i) => i.name === name);
  return icon ? <FontAwesomeIcon icon={icon.icon} size="1x" fixedWidth={true} /> : null;
};

export default Icon;

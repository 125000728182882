import React from 'react';
import { buildTheRows, buildTheColumns, buildColumnOptions } from '../../util/customData';
import { Table } from './Table';
import { PanelData, FieldConfigSource } from '@grafana/data';
import { SimpleOptions, MultiSelect } from 'types';

interface TableWrapperProps {
  data: PanelData;
  fieldConfig: FieldConfigSource;
  options: SimpleOptions;
  multiSelects: MultiSelect[];
  updateMultiSelects: (name: string, value: number) => void;
  resetMultiSelects: () => void;
  height: number;
}

export const TableWrapper = (props: TableWrapperProps) => {
  const requestId = props.data.request && props.data.request.requestId;
  const newDataLength: number = props.data.series[0].fields[0].values.toArray().length;
  const { columns, columnOptions } = React.useMemo(() => {
    console.log('Rebuilding Columns', requestId, 'rows => ', newDataLength);
    const columnOptions = buildColumnOptions(props.fieldConfig);
    const columns = buildTheColumns(props.data, props.options, columnOptions);
    return { columns, columnOptions };
  }, [props.data, newDataLength, props.options, requestId, props.fieldConfig]);

  // console.log('Request ID ', requestId);

  const { rows, max } = React.useMemo(() => {
    console.log('Rebuilding Rows', requestId, 'rows => ', newDataLength);
    const { rows, max } = buildTheRows(props.data, props.options, columnOptions);
    return { rows, max };
  }, [newDataLength, props.options, requestId, columnOptions, props.data]);
  // console.log(props.data);
  console.log('Rows > ', rows.length);
  return (
    <Table
      columns={columns}
      data={rows}
      options={props.options}
      columnOptions={columnOptions}
      multiSelects={props.multiSelects}
      updateMultiSelects={props.updateMultiSelects}
      resetMultiSelects={props.resetMultiSelects}
      height={props.height}
      thresholds={props.fieldConfig.defaults.thresholds}
      max={max}
    />
  );
};

import React, { useState } from 'react';
import { usePopper } from 'react-popper';

const Popper = ({ children, popId, currentPopper, setCurrentPopper }) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const [open, setOpen] = useState(false);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'arrow',
        options: { element: arrowElement },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
    placement: 'auto',
  });

  if (currentPopper === popId && !open) {
    setOpen(true);
  }

  if (currentPopper !== popId && open) {
    setOpen(false);
  }

  return (
    <>
      <button
        type="button"
        ref={setReferenceElement as any}
        onClick={() => {
          setCurrentPopper(open ? null : popId);
          // setOpen(!open);
        }}
      >
        More
      </button>

      {open && (
        <div className="tb-popper" ref={setPopperElement as any} style={styles.popper} {...attributes.popper}>
          {children}
          <div className="pop-arrow" ref={setArrowElement as any} style={styles.arrow} />
        </div>
      )}
    </>
  );
};

export default Popper;

import React from 'react';
import { CSVLink } from 'react-csv';
import format from 'date-fns/esm/format';

const ExportCSV = ({ columns, rows, prepareRow, columnOptions }) => {
  const buildCSVData = () => {
    const headers = columns.map((column) => column.Header);
    const rowData = rows.map((item) => {
      prepareRow(item);
      return item.cells.map((cell) => {
        const type = columnOptions.columnTypes[cell.column.Header];
        return convertCell(cell.value, type);
      });
    });
    return [headers].concat(rowData);
  };

  const convertCell = (value: any, type: string) => {
    if (type === 'Date' || type === 'DateTime') {
      if (!value) {
        return '';
      }
      const dt: Date = new Date(value);
      const formattedDate = format(dt, 'yyyy-MM-dd');
      const formattedTime = type === 'DateTime' ? ` ${dt.toLocaleTimeString()}` : '';
      return `${formattedDate}${formattedTime}`;
    }
    return value;
  };

  return (
    <CSVLink className="csv-link" data={buildCSVData()}>
      Download CSV
    </CSVLink>
  );
};

export default ExportCSV;

import React from 'react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export const DateColumnFilter = ({ column: { filterValue = [], setFilter } }) => {
  const type = filterValue[0];
  return (
    <div>
      <select
        value={type || ''}
        onChange={(e) => {
          const value = e.target.value;
          setFilter((old = []) => [value, old[1] || '', old[2] || ''] || undefined);
        }}
      >
        <option value="">All</option>
        <option value="blank">Is blank</option>
        <option value="notblank">Is not blank</option>
        <option value="date">Date Range</option>
        <option value="equal">Single Day</option>
        <option value="before">Less or Equal to</option>
        <option value="after">Greater or Equal to</option>
      </select>
      {(type === 'date' || type === 'after' || type === 'equal') && (
        <DatePicker
          dateFormat="dd/MM/yyyy"
          selected={filterValue[1] || null}
          placeholderText="Choose Date"
          isClearable={true}
          maxDate={type === 'date' ? filterValue[2] || null : null}
          onChange={(date) => {
            setFilter((old = []) => [old[0], date, old[2]]);
          }}
        />
      )}
      {(type === 'date' || type === 'before') && (
        <DatePicker
          dateFormat="dd/MM/yyyy"
          selected={filterValue[2] || null}
          placeholderText="Choose Date"
          isClearable={true}
          minDate={type === 'date' ? filterValue[1] || null : null}
          onChange={(date) => {
            setFilter((old = []) => [old[0], old[1], date]);
          }}
        />
      )}
    </div>
  );
};

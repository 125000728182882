import isSameDay from 'date-fns/esm/isSameDay';

export const dateRangeFilterFn = (rows, id, filterValue) => {
  const startDate = filterValue[1] ? new Date(filterValue[1]) : undefined;
  const endDate = filterValue[2] ? new Date(filterValue[2]) : undefined;
  if (endDate) {
    endDate.setHours(23, 59, 999, 999);
  }
  return rows.filter((row) => {
    const searchType = filterValue[0];
    const rowValue = row.values[id] || '';
    const rowValueDate = new Date(rowValue);
    const before = !endDate || rowValueDate <= endDate;
    const after = !startDate || rowValueDate >= startDate;
    const inRange = before && after;
    const equal = !startDate || isSameDay(rowValueDate, startDate);
    const isEmpty = rowValue.length === 0 || rowValue === 'null';
    return (
      searchType === '' ||
      (searchType === 'blank' && isEmpty) ||
      (searchType === 'notblank' && !isEmpty) ||
      (searchType === 'date' && inRange) ||
      (searchType === 'before' && before) ||
      (searchType === 'after' && after) ||
      (searchType === 'equal' && equal)
    );
  });
};

export const numberFilterFn = (rows, id, filterValue) => {
  return numberFilter(rows, id, filterValue, 1);
};

export const percentFilterFn = (rows, id, filterValue) => {
  return numberFilter(rows, id, filterValue, 100);
};

export const numberFilter = (rows, id, filterValue, multiplier) => {
  const searchType = filterValue[0];
  const searchTerm = filterValue[1];
  const numberTerm = Number(searchTerm);
  return rows.filter((row) => {
    const rowValue = row.values[id] || '';
    const numberValue = Number(rowValue) * multiplier;
    const isEmpty = rowValue.length === 0 || rowValue === 'null';
    return (
      searchType === '' ||
      (searchType === 'equal' && (numberValue === numberTerm || searchTerm.length === 0)) ||
      (searchType === 'less' && (numberValue < numberTerm || searchTerm.length === 0)) ||
      (searchType === 'more' && (numberValue > numberTerm || searchTerm.length === 0)) ||
      (searchType === 'blank' && isEmpty) ||
      (searchType === 'notblank' && !isEmpty)
    );
  });
};

export const textFilterFn = (rows, id, filterValue) => {
  return rows.filter((row) => {
    const searchType = filterValue[0];
    const searchTerm = filterValue[1];
    const rowValue = row.values[id] || '';
    // prettier-ignore
    const containsValue = String(rowValue)
      .toLowerCase()
      .includes(String(searchTerm)
      .toLowerCase());
    const isEmpty = rowValue.length === 0 || rowValue === 'null';
    return (
      searchType === '' ||
      (searchType === 'inc' && (containsValue || searchTerm.length === 0)) ||
      (searchType === 'exc' && (!containsValue || searchTerm.length === 0)) ||
      (searchType === 'blank' && isEmpty) ||
      (searchType === 'notblank' && !isEmpty)
    );
  });
};

export const selectFilterFn = (rows, id, filterValue) => {
  return rows.filter((row) => {
    const searchTerm = filterValue;
    const rowValue = row.values[id] || '';
    // const exists = rows.filter(r => r.values[id].includes(searchTerm)).length;
    return searchTerm === '' || rowValue === searchTerm;
  });
};

export const booleanFilterFn = (rows, id, filterValue) => {
  return rows.filter((row) => {
    const searchTerm = filterValue;
    const rowValue = row.values[id];
    // const exists = rows.filter(r => r.values[id].includes(searchTerm)).length;
    return searchTerm === '' || (searchTerm === 'false' && !rowValue) || (searchTerm === 'true' && rowValue);
  });
};

import React from 'react';
import { formatNumber } from './TableCell';
import { ColumnOptions } from 'types';
import { getColumnProperty } from 'util/customData';

interface TableFooterCellProps {
  column: any;
  columnOptions: ColumnOptions;
  rows: any;
}

const TableFooterCell = ({ column, columnOptions, rows }: TableFooterCellProps): React.ReactElement => {
  const type = columnOptions.columnTypes[column.Header];
  switch (type) {
    case 'Currency':
    case 'Numeric':
    case 'Decimal':
      const showFooter = getColumnProperty(columnOptions, column.Header, 'showFooter');
      return <>{showFooter ? formatNumber(column.Footer(rows), type) : ''}</>;

    default:
      return <></>;
  }
};

export default TableFooterCell;

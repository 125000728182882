import React from 'react';

const Pagination = ({
  pageSize,
  setPageSize,
  canPreviousPage,
  gotoPage,
  previousPage,
  pageIndex,
  pageOptions,
  nextPage,
  canNextPage,
  pageCount,
}) => {
  return (
    <div className="pagination">
      <select
        className="pagesize-select"
        value={pageSize}
        onChange={(e) => {
          setPageSize(Number(e.target.value));
        }}
      >
        {[10, 20, 30, 40, 50].map((pSize) => (
          <option key={pSize} value={pSize}>
            Show {pSize}
          </option>
        ))}
      </select>

      <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
        {'<<'}
      </button>
      <button onClick={() => previousPage()} disabled={!canPreviousPage}>
        {'<'}
      </button>

      <span className="page-totals">
        Page
        <input
          type="number"
          value={pageIndex + 1}
          onChange={(e) => {
            const pg = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(pg);
          }}
        />
        of <strong>{pageOptions.length}</strong>
      </span>

      <button onClick={() => nextPage()} disabled={!canNextPage}>
        {'>'}
      </button>

      <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
        {'>>'}
      </button>
    </div>
  );
};

export default Pagination;

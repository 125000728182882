import React, { useState } from 'react';

import { useTable, useSortBy, usePagination, useFilters, useColumnOrder, useGlobalFilter } from 'react-table';

import {
  dateRangeFilterFn,
  numberFilterFn,
  textFilterFn,
  selectFilterFn,
  booleanFilterFn,
  percentFilterFn,
} from '../../util/filters';

import { DefaultColumnFilter } from './filters/DefaultColumnFilter';
import { columnWidths } from '../../util/customSettings';

import TableControls from './controls/TableControls';
import TableCell from './TableCell';
import Icon from '../table/controls/Icon';

import { getColumnProperty } from '../../util/customData';

import { Tooltip } from '@grafana/ui';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleUp as sortUp } from '@fortawesome/free-solid-svg-icons/faArrowAltCircleUp';
import { faArrowAltCircleDown as sortDown } from '@fortawesome/free-solid-svg-icons/faArrowAltCircleDown';
import { faSort as notSorted } from '@fortawesome/free-solid-svg-icons/faSort';
import { SimpleOptions, MultiSelect, ColumnOptions } from 'types';
import TableFooterCell from './TableFooterCell';
import { ThresholdsConfig } from '@grafana/data';
import { getThresholdColor } from 'util/colorFunctions';

interface TableProps {
  columns: any;
  data: any;
  options: SimpleOptions;
  columnOptions: ColumnOptions;
  multiSelects: MultiSelect[];
  updateMultiSelects: (name: string, value: number) => void;
  resetMultiSelects: () => void;
  height: number;
  thresholds: ThresholdsConfig | undefined;
  max: number;
}

const ShowTooltip = ({ description }: { description: string }) => {
  if (!description) {
    return null;
  }
  return (
    <Tooltip content={<div>{description}</div>} theme="info" placement="top">
      <span className="rr-table__icon">
        <Icon name="info" />
      </span>
    </Tooltip>
  );
};

const ShowSortIcon = ({ isSorted, isSortedDesc }: { isSorted: boolean; isSortedDesc: boolean }) => {
  const icon = isSorted ? (isSortedDesc ? sortUp : sortDown) : notSorted;
  return (
    <span className={`rr-table__icon ${isSorted ? 'active' : ''}`}>
      <FontAwesomeIcon icon={icon} size="1x" fixedWidth={true} />
    </span>
  );
};

export const Table = ({
  columns,
  data,
  options,
  columnOptions,
  multiSelects,
  updateMultiSelects,
  resetMultiSelects,
  thresholds,
  max,
}: TableProps) => {
  const [currentPopper, setCurrentPopper] = useState(null);

  const filterTypes = React.useMemo(
    () => ({
      dateRange: dateRangeFilterFn,
      number: numberFilterFn,
      percent: percentFilterFn,
      select: selectFilterFn,
      text: textFilterFn,
      boolean: booleanFilterFn,
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page,
    rows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setColumnOrder,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      autoResetGlobalFilter: !options.persistGlobalSearch,
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: {
        pageIndex: 0,
        // pageSize: 15,
        pageSize: !options.showPagination || !options.showHeader ? 1000 : parseInt(options.pageSize, 10),
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useColumnOrder
  );

  function getColumnClass(columnName: string, columnOptions: ColumnOptions) {
    // console.log('Column Name ', columnName);
    if (columnName === 'More') {
      return 'more';
    }
    // const colType = getColumnProperty(columnOptions, columnName, 'columnType');
    const colType = columnOptions.columnTypes[columnName];
    return colType ? colType.toLowerCase() : 'unset';
  }

  function getColumnWidth(columnName: string, columnOptions: ColumnOptions) {
    if (columnName === 'More') {
      return 2;
    }
    const colWidthOverride = getColumnProperty(columnOptions, columnName, 'widthOverride');
    // const colType = getColumnProperty(columnOptions, columnName, 'columnType');
    const colType = columnOptions.columnTypes[columnName];
    if (colWidthOverride) {
      return colWidthOverride;
    }

    const columnType = columnWidths.find((c) => c.columnType === colType);
    return columnType ? columnType.defaultWidth : 1;
  }

  function getCellStyle(cell) {
    const cssName = getCssName(cell);
    const styles: any = { flexGrow: getColumnWidth(cell.column.Header, columnOptions) };
    const type = columnOptions.columnTypes[cell.column.Header];
    if (
      ['Numeric', 'Percent', 'Decimal', 'Currency'].includes(type) &&
      options.applyThresholds &&
      thresholds &&
      getColumnProperty(columnOptions, cell.column.Header, 'applyThresholds') === true
    ) {
      const { background, textColour } = getThresholdColor(cell, thresholds, max);
      styles.background = background;
      styles.color = textColour;
    }
    return { styles, cssName };
  }

  function getCssName(cell) {
    let cssName = `col-${getColumnClass(cell.column.Header, columnOptions)}`;
    if (typeof cell.value === 'string' && options.highlightKeyword) {
      cssName += cell.value?.toString().toLowerCase() === options.highlightKeyword?.toLowerCase() ? ' cell-total' : '';
    }
    return cssName;
  }

  return (
    <div className="table-wrapper">
      {options.showHeader && (
        <TableControls
          options={options}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          rows={rows}
          columns={columns}
          data={data}
          multiSelects={multiSelects}
          columnOptions={columnOptions}
          order={options.order}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          pageCount={pageCount}
          gotoPage={gotoPage}
          nextPage={nextPage}
          previousPage={previousPage}
          setPageSize={setPageSize}
          setColumnOrder={setColumnOrder}
          pageIndex={pageIndex}
          pageSize={pageSize}
          prepareRow={prepareRow}
          resetMultiSelects={resetMultiSelects}
        />
      )}
      <div className="rr-table__wrapper">
        <table className="rr-table" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup: any, index: number) => (
              <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any, cindex: number) => (
                  <th
                    key={cindex}
                    {...column.getHeaderProps({ className: `col-${getColumnClass(column.Header, columnOptions)}` })}
                    style={{ flexGrow: getColumnWidth(column.Header, columnOptions) }}
                  >
                    {options.showInfo && (
                      <div
                        className="rr-table__info"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          borderBottom: '1px solid #ccc',
                          marginBottom: 5,
                        }}
                      >
                        <span>{getColumnClass(column.Header, columnOptions)}</span>
                        <span>(W{getColumnWidth(column.Header, columnOptions)})</span>
                      </div>
                    )}

                    <div className="rr-table__label-block">
                      {column.canSort && (
                        <span {...column.getSortByToggleProps()} className="sorty">
                          <ShowSortIcon isSorted={column.isSorted} isSortedDesc={column.isSortedDesc} />
                        </span>
                      )}
                      <span {...column.getHeaderProps()}>{column.render('Header')}</span>
                      <ShowTooltip description={getColumnProperty(columnOptions, column.Header, 'description')} />
                    </div>

                    <div className="filter">{column.canFilter ? column.render('Filter') : null}</div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ height: options.showFooter ? 'calc(100% - 26px)' : '100%' }}>
            {page.map((row: any, i: number) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell: any, cindex: number) => {
                    const { cssName, styles } = getCellStyle(cell);
                    return (
                      <td
                        key={cindex}
                        {...cell.getCellProps({
                          className: cssName,
                        })}
                        style={styles}
                      >
                        <TableCell
                          cell={cell}
                          options={options}
                          columnOptions={columnOptions}
                          multiSelects={multiSelects}
                          updateMultiSelects={updateMultiSelects}
                          currentPopper={currentPopper}
                          setCurrentPopper={setCurrentPopper}
                          thresholds={thresholds}
                        />
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          {options.showFooter && (
            <tfoot>
              {footerGroups.map((group) => (
                <tr {...group.getFooterGroupProps()} key={group.id}>
                  {group.headers.map((column: any, cindex: number) => (
                    <td
                      {...column.getFooterProps({ className: `col-${getColumnClass(column.Header, columnOptions)}` })}
                      key={cindex}
                      style={{ flexGrow: getColumnWidth(column.Header, columnOptions) }}
                    >
                      <TableFooterCell column={column} columnOptions={columnOptions} rows={rows} />
                    </td>
                  ))}
                </tr>
              ))}
            </tfoot>
          )}
        </table>
      </div>
    </div>
  );
};

import React from 'react';

export const SelectColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
  const options = React.useMemo(() => {
    const opts = new Set<string>();
    preFilteredRows.forEach((row) => {
      if (row.values[id]) {
        if (typeof row.values[id] === 'string') {
          row.values[id].split('^').forEach((vRow) => opts.add(vRow));
        } else {
          opts.add(row.values[id]);
        }
      }
    });
    return [...opts.values()].sort();
  }, [id, preFilteredRows]);

  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

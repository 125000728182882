import React from 'react';
import ContextMenu from './actions/ContextMenu';
import format from 'date-fns/esm/format';
import ActionItem from './actions/ActionItem';
import { SimpleOptions, MultiSelect, ColumnOptions } from 'types';
import { ThresholdsConfig } from '@grafana/data';

export const formatNumber = (value: any, type: string) => {
  // console.log(value);
  // console.log(typeof value);
  if (typeof value !== 'number') {
    return '';
  }
  switch (type) {
    case 'Numeric':
      return addCommas(value.toString());
    case 'Percent':
      return `${rounded(value * 100)}%`;
    case 'Decimal':
      return rounded(value);
    case 'Currency':
      return `£${addCommas(rounded(value))}`;
    default:
      return value;
  }
};

const addCommas = (value: string) => {
  return value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const rounded = (value: number) => {
  return (Math.round((value + Number.EPSILON) * 100) / 100).toFixed(2);
};

interface TableCellProps {
  options: SimpleOptions;
  columnOptions: ColumnOptions;
  cell: any;
  multiSelects: MultiSelect[];
  updateMultiSelects: (name: string, value: number) => void;
  currentPopper: any;
  setCurrentPopper: any;
  thresholds: ThresholdsConfig | undefined;
}

const TableCell = ({
  options,
  columnOptions,
  cell,
  multiSelects,
  updateMultiSelects,
  currentPopper,
  setCurrentPopper,
  thresholds,
}: TableCellProps) => {
  if (cell.column.Header === 'More') {
    return (
      <ContextMenu
        columnOptions={columnOptions}
        cell={cell}
        currentPopper={currentPopper}
        setCurrentPopper={setCurrentPopper}
      />
    );
  } else {
    // const type = getColumnProperty(columnOptions, cell.column.Header, 'columnType');
    const type = columnOptions.columnTypes[cell.column.Header];
    switch (type) {
      case 'DateTime':
      case 'Date':
        if (!cell.value) {
          return '';
        }
        const dt: Date = new Date(cell.value);
        return (
          <span>
            {format(dt, 'E do LLL yyyy')}
            {type === 'DateTime' && <span className="time">{dt.toLocaleTimeString()}</span>}
          </span>
        );
      case 'MonthYear':
        if (!cell.value) {
          return '';
        }
        const my: Date = new Date(cell.value);
        return format(my, 'LLLL yyyy');
      case 'Boolean':
        return <div className={`cell-boolean--${cell.value}`}>{cell.value ? 'True' : 'False'}</div>;
      case 'Id':
        return cell.value === 'null' || cell.value === null ? '' : cell.value;
      case 'Currency':
      case 'Numeric':
      case 'Decimal':
      case 'Percent':
        return formatNumber(cell.value, type);
      case 'Bullets':
        if (!cell.value) {
          return '';
        } else {
          return (
            <ul>
              {cell.value.split('^').map((item: string, i: number) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          );
        }
      case 'Action':
        return (
          <ActionItem
            columnOptions={columnOptions}
            cell={cell}
            multiSelects={multiSelects}
            updateMultiSelects={updateMultiSelects}
          />
        );
      default:
        return cell.render('Cell');
    }
  }
};

export default TableCell;

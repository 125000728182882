import React from 'react';
import Button from '../controls/Button';
import { MultiSelect, ColumnOptions } from 'types';
import { getColumnProperty } from 'util/customData';

interface TableCellProps {
  columnOptions: ColumnOptions;
  cell: any;
  multiSelects: MultiSelect[];
  updateMultiSelects: (name: string, value: number) => void;
}

const ActionItem = ({ columnOptions, cell, multiSelects, updateMultiSelects }: TableCellProps) => {
  const actionType = getColumnProperty(columnOptions, cell.column.Header, 'actionType');
  const actionIcon = getColumnProperty(columnOptions, cell.column.Header, 'actionIcon');
  const actionButtonText = getColumnProperty(columnOptions, cell.column.Header, 'actionButtonText');
  const actionUrl = getColumnProperty(columnOptions, cell.column.Header, 'actionUrl');
  const actionMode = getColumnProperty(columnOptions, cell.column.Header, 'actionMode');

  switch (actionType) {
    case 'MultiSelect':
      const active = multiSelects.find((ms) => ms.name === cell.column.Header && ms.value === cell.value);
      return (
        <Button
          className={active ? 'active' : ''}
          handleClick={() => {
            updateMultiSelects(cell.column.Header, cell.value);
          }}
          text={active ? 'Deselect' : 'Select'}
        />
      );
    default:
      const url = actionUrl ? actionUrl.replace('[id]', cell.value) : '';

      return (
        <Button
          handleClick={() => {
            if (actionMode === 'SameTab') {
              window.open(url, '_self');
            } else {
              window.open(url);
            }
          }}
          icon={actionIcon}
          text={actionButtonText}
        />
      );
  }
};

export default ActionItem;

import { PanelData, FieldConfigSource, ConfigOverrideRule } from '@grafana/data';
import { DateColumnFilter } from '../components/table/filters/DateColumnFilter';
import { BooleanColumnFilter } from '../components/table/filters/BooleanColumnFilter';
import { DefaultColumnFilter } from '../components/table/filters/DefaultColumnFilter';
import { NumberColumnFilter } from '../components/table/filters/NumberColumnFilter';
import { SelectColumnFilter } from '../components/table/filters/SelectColumnFilter';
import { SimpleOptions, ColumnOptions, CustomFieldConfig } from 'types';

export const buildTheColumns = (pData: PanelData, options: SimpleOptions, columnOptions: ColumnOptions) => {
  const { series } = pData;

  if (series.length === 0) {
    return {};
  }

  const buildColumns: object[] = [];

  series[0].fields.map((field, i) => {
    // const col = field.name;
    const displayName = field.config.displayName || field.name;
    const column: {
      Filter?: any;
      Header: string;
      Footer: any;
      accessor: string;
      filter?: any;
      disableFilters?: boolean;
      defaultCanSort?: boolean;
      disableSortBy?: boolean;
      sortType?: string;
      min?: number | null | undefined;
      max?: number | null | undefined;
    } = {
      Header: displayName,
      accessor: displayName,
      Footer: displayName,
    };
    let columnType = getColumnProperty(columnOptions, displayName, 'columnType');
    columnType =
      columnType !== 'Default' ? columnType : guessColumnType(columnType, displayName, field.values.toArray());
    columnOptions.columnTypes[displayName] = columnType;

    const actionType = getColumnProperty(columnOptions, displayName, 'actionType');

    if (columnType !== 'Hidden' && actionType !== 'Context') {
      const filter = getFilterType(columnType);
      if (filter.length > 0) {
        column.Filter = filter[0];
        column.filter = filter[1];
      } else {
        column.disableFilters = true;
        column.defaultCanSort = false;
        column.disableSortBy = true;
      }

      if (
        columnType === 'Decimal' ||
        columnType === 'Numeric' ||
        columnType === 'Currency' ||
        columnType === 'Percent'
      ) {
        column.sortType = 'basic';
        column.min = field.config.min;
        column.max = field.config.max;
      }

      if (columnType === 'Decimal' || columnType === 'Numeric' || columnType === 'Currency') {
        column.Footer = (rows) => {
          // const total = React.useMemo(() => rows.reduce((sum, row) => row.values[displayName] + Number(sum), 0), [
          //   rows,
          // ]);
          const total = rows.reduce((sum, row) => row.values[displayName] + Number(sum), 0);
          return total;
        };
      }

      buildColumns.push(column);
    }
  });

  if (getHasOverrideWithValue(columnOptions, 'actionType', 'Context').length > 0) {
    buildColumns.push({
      Header: 'More',
      accessor: '',
      defaultCanSort: false,
      disableFilters: true,
      disableSortBy: true,
    });
  }
  return buildColumns;
};

export const getColumnProperty = (columnOptions: ColumnOptions, columnName: string, property: string) => {
  return columnName in columnOptions.columns && property in columnOptions.columns[columnName]
    ? columnOptions.columns[columnName][property]
    : columnOptions.defaults[property];
};

export const getHasOverrideWithValue = (columnOptions: ColumnOptions, propertyId: string, propertyValue: string) => {
  const cols: string[] = [];
  for (let [key, value] of Object.entries(columnOptions.columns)) {
    if (value[propertyId] === propertyValue) {
      cols.push(key);
    }
  }
  return cols;
};

const getFilterType = (colType: string) => {
  switch (colType) {
    case 'Select':
    case 'Bullets':
      return [SelectColumnFilter, 'select'];
    case 'Boolean':
      return [BooleanColumnFilter, 'boolean'];
    case 'DateTime':
    case 'Date':
    case 'MonthYear':
      return [DateColumnFilter, 'dateRange'];
    case 'MultiSelect':
    case 'Action':
      return [];
    case 'Id':
    case 'Numeric':
    case 'Currency':
    case 'Decimal':
      return [NumberColumnFilter, 'number'];
    case 'Percent':
      return [NumberColumnFilter, 'percent'];
    default:
      return [DefaultColumnFilter, 'text'];
  }
};

export const rounded4 = (value: number) => {
  return (Math.round((value + Number.EPSILON) * 10000) / 10000).toFixed(4);
};

export const buildTheRows = (pData: PanelData, options: SimpleOptions, columnOptions: ColumnOptions) => {
  const { series } = pData;

  // console.log('series > ', series);
  // console.log('columnOptions > ', columnOptions);

  if (series.length === 0) {
    return { rows: [], max: 0 };
  }

  const rows: object[] = [];
  let max = 0;

  series[0].fields[0].values.toArray().map((column, rowID) => {
    const row: { [index: string]: any } = {};
    for (const field of series[0].fields) {
      const displayName = field.config.displayName || field.name;
      // const type = getColumnProperty(columnOptions, displayName, 'columnType');
      const type = columnOptions.columnTypes[displayName];
      if (type !== 'Hidden') {
        let value: any = field.values.toArray()[rowID];
        // fix for massive fp numbers that break the sort (all numbers are rounded to 2dp) - update use sorttype = basic instead
        // if (typeof value === 'number') {
        //   value = Number(rounded4(Number(value)));
        // }
        row[displayName] = value;
        if (
          ['Numeric', 'Percent', 'Decimal', 'Currency'].includes(type) &&
          getColumnProperty(columnOptions, displayName, 'applyThresholds') === true
        ) {
          max = value > max ? value : max;
        }
      }
    }
    rows.push(row);
  });

  // console.log('max => ', max);

  return { max, rows };
};

export const getColumns = (pData: PanelData) => {
  const { series } = pData;

  if (series.length === 0) {
    return [];
  }

  return series[0].fields.map((item) => item.name);
};

export const buildColumnOptions = (fieldConfig: FieldConfigSource<CustomFieldConfig>): ColumnOptions => {
  const columnOptions: ColumnOptions = {
    defaults: { ...fieldConfig.defaults.custom },
    columns: {},
    columnTypes: {},
  };

  fieldConfig.overrides.forEach((item) => {
    columnOptions.columns[item.matcher.options] = getOverrideProperties(item);
  });

  return columnOptions;
};
const getOverrideProperties = (item: ConfigOverrideRule) => {
  const props = {};
  item.properties.forEach((property) => {
    props[property.id.substring(7)] = property.value;
  });
  return props;
};

const guessColumnType = (columnType: string, name: string, values: Array<string | number | boolean>): string => {
  const guess = 'SmallString';
  if (typeof values[0] === 'boolean') {
    return 'Boolean';
  }
  if (typeof values[0] === 'number' && !name.toLowerCase().includes('id')) {
    // if (Math.min(...(values as number[])) >= 0 && Math.max(...(values as number[])) <= 1) {
    //   return 'Boolean';
    // } else {
    return 'Numeric';
    // }
  }

  if (typeof values[0] === 'string') {
    if (
      new Date(values[0]).toString() !== 'Invalid Date' &&
      (name.toLowerCase().includes('created') || name.toLowerCase().includes('date'))
    ) {
      return 'DateTime';
    } else {
      const bullets = values.filter((v) => ((v as string) || '').indexOf('^') > 0);
      if (bullets.length > 0) {
        return 'Bullets';
      }
    }
  }
  return guess;
};

import React from 'react';
import Button from './Button';

import { MultiSelect, ColumnOptions } from 'types';
import { getHasOverrideWithValue, getColumnProperty } from 'util/customData';

interface MultiSelectButtonsProps {
  columnOptions: ColumnOptions;
  multiSelects: MultiSelect[];
  resetMultiSelects: () => void;
}

const MultiSelectButtons = ({ columnOptions, multiSelects, resetMultiSelects }: MultiSelectButtonsProps) => {
  // const multis = optionColumns.filter(c => c.type === 'Action' && c.actionType === 'MultiSelect');

  const multis = getHasOverrideWithValue(columnOptions, 'actionType', 'MultiSelect');

  return (
    <div className="multi-launches">
      {multis.map((ms, buttonId) => {
        const selecteds = multiSelects.filter((m) => m.name === ms);
        if (selecteds.length === 0) {
          return '';
        }
        let actionUrl = getColumnProperty(columnOptions, ms, 'actionUrl');
        const actionMode = getColumnProperty(columnOptions, ms, 'actionMode');
        const actionParameter = getColumnProperty(columnOptions, ms, 'actionParameter');
        const actionIcon = getColumnProperty(columnOptions, ms, 'actionIcon');
        const actionButtonText = getColumnProperty(columnOptions, ms, 'actionButtonText');

        selecteds.forEach((item) => {
          actionUrl += `&${actionParameter}=${item.value}`;
        });
        return (
          <>
            <Button
              key={buttonId}
              handleClick={() => {
                if (actionMode === 'SameTab') {
                  window.open(actionUrl, '_self');
                } else {
                  window.open(actionUrl);
                }
              }}
              icon={actionIcon ? actionIcon : false}
              text={`${actionButtonText} (${selecteds.length})`}
            />
          </>
        );
      })}
      <span className="multi-deselect" onClick={resetMultiSelects}>
        Deselect
      </span>
    </div>
  );
};

export default MultiSelectButtons;

import React from 'react';
import Popper from './Popper';
import Icon from '../controls/Icon';
import { ColumnOptions } from 'types';
import { getHasOverrideWithValue, getColumnProperty } from 'util/customData';

interface ContextMenuProps {
  columnOptions: ColumnOptions;
  cell: any;
  currentPopper: any;
  setCurrentPopper: any;
}

const ContextMenuGroup = ({ group, columnOptions, columns, cell }) => {
  return (
    <div className="cm__group">
      <h2 className="cm__group-title">{group}</h2>
      <ul className="cm__items">
        {columns.map((column, i) => (
          <ContextMenuItem key={i} columnOptions={columnOptions} column={column} cell={cell} />
        ))}
      </ul>
    </div>
  );
};

const ContextMenuItem = ({ columnOptions, column, cell }) => {
  const actionIcon = getColumnProperty(columnOptions, column, 'actionIcon');
  const actionButtonText = getColumnProperty(columnOptions, column, 'actionButtonText');
  const actionUrl = getColumnProperty(columnOptions, column, 'actionUrl');
  const actionMode = getColumnProperty(columnOptions, column, 'actionMode');
  const id = cell.row.original[column];
  const url = actionUrl ? actionUrl.replace('[id]', id) : '';
  return (
    <li
      className="cm__item"
      onClick={() => {
        if (actionMode === 'Same Tab') {
          window.open(url, '_self');
        } else {
          window.open(url);
        }
      }}
    >
      {actionIcon !== '' && (
        <span className="button__icon">
          <Icon name={actionIcon} />{' '}
        </span>
      )}
      {actionButtonText}
    </li>
  );
};

const ContextMenu = ({ columnOptions, cell, currentPopper, setCurrentPopper }: ContextMenuProps) => {
  const contextColumns = getHasOverrideWithValue(columnOptions, 'actionType', 'Context');
  const contextGroups = Array.from(
    new Set(contextColumns.map((col) => getColumnProperty(columnOptions, col, 'actionGroup')))
  );

  return (
    <Popper popId={cell.row.id} currentPopper={currentPopper} setCurrentPopper={setCurrentPopper}>
      <nav className="cm">
        {contextGroups.map((group, i) => (
          <ContextMenuGroup
            key={i}
            group={group}
            columnOptions={columnOptions}
            columns={getHasOverrideWithValue(columnOptions, 'actionGroup', group)}
            cell={cell}
          />
        ))}
      </nav>
    </Popper>
  );
};

export default ContextMenu;

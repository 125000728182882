import React from 'react';
import Icon, { iconKey } from '../table/controls/Icon';

const IconEditor = ({ icon, updateIcon }) => {
  return (
    <div>
      <div className="icon-items">
        {iconKey.map((option) => (
          <div
            key={option.name}
            className={`icon-item ${icon === option.name ? 'active' : ''}`}
            onClick={() => updateIcon(option.name)}
          >
            <Icon name={option.name} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default IconEditor;

import React from 'react';

export const BooleanColumnFilter = ({ column: { filterValue, setFilter } }) => {
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      <option value="true">True</option>
      <option value="false">False</option>
    </select>
  );
};

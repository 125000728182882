import React from 'react';
import Icon from './Icon';

const Button = ({ icon = '', text, className = '', handleClick }) => {
  return (
    <button className={className} onClick={handleClick}>
      {icon !== '' && (
        <span className="button__icon">
          <Icon name={icon} />{' '}
        </span>
      )}
      {text}
    </button>
  );
};

export default Button;

import React from 'react';

import Pagination from './Pagination';
import MultiSelectButtons from './MultiSelectsButtons';
import ExportCSV from './ExportCSV';

import { GlobalFilter } from './GlobalFilter';

const Results = ({ rows, data }) => {
  return (
    <span className="results">
      Results: <strong>{rows.length}</strong> of {data.length} records
    </span>
  );
};

const TableControls = (props) => {
  return (
    <div className="rr-table__controls">
      <div className="controls-left">
        {props.options.showGlobalSearch && (
          <GlobalFilter globalFilter={props.globalFilter} setGlobalFilter={props.setGlobalFilter} />
        )}
        {props.options.showResultsCount && <Results rows={props.rows} data={props.data} />}
      </div>

      {props.multiSelects.length > 0 && (
        <div className="controls-center">
          <MultiSelectButtons
            columnOptions={props.columnOptions}
            multiSelects={props.multiSelects}
            resetMultiSelects={props.resetMultiSelects}
          />
        </div>
      )}

      <div className="controls-right">
        {props.options.showDownload && (
          <ExportCSV
            columns={props.columns}
            rows={props.rows}
            prepareRow={props.prepareRow}
            columnOptions={props.columnOptions}
          />
        )}

        {/* <button onClick={() => props.setColumnOrder(props.order.split(','))}>*</button> */}

        {props.options.showPagination && (
          <Pagination
            pageSize={props.pageSize}
            setPageSize={props.setPageSize}
            gotoPage={props.gotoPage}
            canPreviousPage={props.canPreviousPage}
            previousPage={props.previousPage}
            pageIndex={props.pageIndex}
            pageOptions={props.pageOptions}
            nextPage={props.nextPage}
            canNextPage={props.canNextPage}
            pageCount={props.pageCount}
          />
        )}
      </div>
    </div>
  );
};

export default TableControls;

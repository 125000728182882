import React from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions, MultiSelect } from 'types';
import { TableWrapper } from './table/TableWrapper';

interface Props extends PanelProps<SimpleOptions> {}

import '../styles/styles.scss';

export class CustomList extends React.Component<Props, { multiSelects: MultiSelect[] }> {
  constructor(props: Props) {
    super(props);
    this.state = {
      multiSelects: [],
    };
  }

  private updateMultiSelects = (name: string, value: number) => {
    let multis = [...this.state.multiSelects];
    if (multis.find((ms) => ms.name === name && ms.value === value)) {
      multis = multis.filter((ms) => ms.name !== name || ms.value !== value);
    } else {
      multis.push({
        name,
        value,
      });
    }
    this.setState({
      multiSelects: multis,
    });
  };

  private resetMultiSelects = () => {
    this.setState({
      multiSelects: [],
    });
  };

  render() {
    console.log('props > ', this.props);

    if (this.props.data.state === 'Error') {
      return <p>{this.props.data.error && this.props.data.error.message}</p>;
    }

    if (this.props.data.series.length === 0 || this.props.data.series[0].fields.length === 0) {
      return <p>Reprocessing</p>;
    }

    return (
      <div className="rradar-list">
        <TableWrapper
          data={this.props.data}
          options={this.props.options}
          fieldConfig={this.props.fieldConfig}
          multiSelects={this.state.multiSelects}
          updateMultiSelects={this.updateMultiSelects}
          resetMultiSelects={this.resetMultiSelects}
          height={this.props.height}
        />
      </div>
    );
  }
}

import { ThresholdsMode, getActiveThreshold, createTheme } from '@grafana/data';

export function getThresholdColor(cell, thresholds, max) {
  const amt = thresholds.mode === ThresholdsMode.Percentage ? (cell.value / max) * 100 : cell.value;
  const thresh = getActiveThreshold(amt, thresholds.steps);
  const theme = createTheme();
  const color = theme.visualization.getColorByName(thresh.color);

  // const color = getColorFromHexRgbOrName(thresh.color);
  const { r, g, b } = color.substring(0, 1) === '#' ? hexToRgb(color) : rgbToRgb(color);
  const brightness = Math.round((r * 299 + g * 587 + b * 114) / 1000);
  const textColour = brightness > 150 ? '#666' : '#eeeeee';
  const background = `linear-gradient(120deg, rgba(${r}, ${g}, ${b}, 0.6), rgba(${r}, ${g}, ${b}, 1))`;
  return { background, textColour };
}

function rgbToRgb(str) {
  var match = str.match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/);
  return match
    ? {
        r: match[1],
        g: match[2],
        b: match[3],
      }
    : { r: 0, g: 0, b: 0 };
}

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : { r: 0, g: 0, b: 0 };
}

import React from 'react';

export const DefaultColumnFilter = ({ column: { filterValue = [], preFilteredRows, setFilter } }) => {
  return (
    <>
      <select
        value={filterValue[0] || ''}
        onChange={(e) => {
          const value = e.target.value;
          setFilter((old = []) => [value, old[1] || ''] || undefined);
        }}
      >
        <option value="">All</option>
        <option value="blank">Is blank</option>
        <option value="notblank">Is not blank</option>
        <option value="inc">Includes</option>
        <option value="exc">Excludes</option>
      </select>
      {(filterValue[0] === 'inc' || filterValue[0] === 'exc') && (
        <input
          value={filterValue[1] || ''}
          onChange={(e) => {
            const value = e.target.value;
            setFilter((old = []) => [old[0] || '', value] || undefined);
          }}
          placeholder="Filter"
        />
      )}
    </>
  );
};

import React from 'react';

export const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
  return (
    <div className="global-search">
      <input
        value={globalFilter || ''}
        onChange={(e) => {
          setGlobalFilter(e.target.value || undefined);
        }}
        placeholder={'Globally Search Here'}
      />
    </div>
  );
};

export const columnWidths = [
  { columnType: 'String', defaultWidth: 8 },
  { columnType: 'SmallString', defaultWidth: 4 },
  { columnType: 'DateTime', defaultWidth: 4 },
  { columnType: 'Date', defaultWidth: 4 },
  { columnType: 'MonthYear', defaultWidth: 4 },
  { columnType: 'Select', defaultWidth: 4 },
  { columnType: 'Boolean', defaultWidth: 3 },
  { columnType: 'Id', defaultWidth: 3 },
  { columnType: 'Numeric', defaultWidth: 3 },
  { columnType: 'Currency', defaultWidth: 3 },
  { columnType: 'Decimal', defaultWidth: 3 },
  { columnType: 'Percent', defaultWidth: 3 },
  { columnType: 'Bullets', defaultWidth: 5 },
  { columnType: 'Action', defaultWidth: 3 },
  { columnType: 'Hidden', defaultWidth: 0 },
  { columnType: 'Default', defaultWidth: 5 },
];
